// _base.scss imports _custom.scss (variables and mixins) and other partials.
@import "base";

// Stores reusable objects like buttons, icons, that are used directly as class inside html (Source: _objects.scss)

/* webks: Tools > overlay */
.webks-tools-page-overlay{
  @include overlay;
}

/* =============================================================================
 *   Buttons
 * ========================================================================== */

.btn,
.btn-inside a{
  @include btn;
}

.btn--width{
  // full width button
  display:block;
}

/* -- COLORS */
/* -- -- Green */
.btn--green,
.btn-inside--green a{
  @include btn-mod('green');
}

/* -- -- Red */
.btn--red,
.btn-inside--red a{
  @include btn-mod('red');
}

/* -- -- Ghost */
.btn--ghost,
.btn-inside--ghost a{
  @include btn-mod('ghost');
}

/* -- Grey to Red */
.btn--grey-to-red{
  @include btn-mod('grey-to-red');
}

/* -- -- Highlight */
.btn--highlight,
.btn-inside--highlight a{
  @include btn-mod('highlight');
}

/* -- Sizes */
.btn--small{
  font-size:$font_small;
  padding:0.15em 15px !important;
}
.btn--big{
  font-size: 18px;
  padding: 0.45em 30.72px !important;
}
.btn--xxl{
  font-size: 24px;
  padding: 0.55em 40.96px !important;
}
.btn--xxxl{
  font-size: 24px;
  padding: 0.65em 46.96px !important;
}

/* -- Icons inside buttons - for example <a class="btn btn--ico ico-zoom-in">Buttontext</a> */
.btn::before{
  display: inline-block;
  font-family: $iconfont;
  font-style: normal;
  font-weight: normal;
  font-size:15px;
  line-height: 1;
  margin-right:5px;
}

/* Special Cases */
/* -- Submit meets button */
.form-submit + .btn,
.btn + .form-submit{
  margin-left:$thin_grid;
}

/* =============================================================================
 *   Icons
 * ========================================================================== */

/* Iconfont */
@font-face {
  font-family: 'iconfont';
  src:url('../fonts/iconfont/iconfont.eot?-cbfawx');
  src:url('../fonts/iconfont/iconfont.eot?#iefix-cbfawx') format('embedded-opentype'),
      url('../fonts/iconfont/iconfont.woff2?-cbfawx') format('woff2'),
      url('../fonts/iconfont/iconfont.woff?-cbfawx') format('woff'),
      url('../fonts/iconfont/iconfont.ttf?-cbfawx') format('truetype'),
      url('../fonts/iconfont/iconfont.svg?-cbfawx#iconfont') format('svg');
  font-weight: normal;
  font-style: normal;
}

// These classes are just for use directly as html class. Dont extend, use the icon mixin instead!
.ico,
.ico-inline{
  @include ico;
}
.ico-seperate{ // Creates an seperate icon out of its own element
  @include ico-replacement;
}
.menu > li > a.ico-inline{
  &::before{
    font-size:16px; // 16px Menu Icons
    font-weight:normal !important;
    vertical-align:middle;
  }
}

/* -- Verfuegbare Icons (see _objects.scss) */
@each $name, $ico in $icon_list {
  .ico-#{$name}:before {
    content: $ico;
  }
}

/* =============================================================================
 *   Box Styles
 * ========================================================================== */

/* Default Box Style */
.box-style{
  @include box-style;
}
.box-style--info{
  @include box-style-mod('info');
}

.image-shadow-box{
  box-shadow:$shadow;
  line-height:0;
}

/* =============================================================================
 *   Misc Objects
 * ========================================================================== */

/* Cutlines */
.cutline-top{
  @include cutline('top');
}
.cutline-bottom{
  @include cutline('bottom');
}

/* Tags (Tagstyle) */
.tagstyle,
.tagstyle ul,
.field-name-field-tags{
  margin:0;
  padding:0;
}
.tagstyle li,
.tagstyle ul li,
.tagstyle .field-item,
.field-name-field-tags .field-item{
  list-style:none;
  margin: 0 0.25em 0.25em 0;
  padding:0;
  display:inline-block;
}
.tagstyle li a,
.tagstyle ul li a,
.tagstyle .field-item a,
.field-name-field-tags .field-item a{
  @include btn('ghost', 'xs');
}

/* Subline & Submitted */
.subline,
.submitted{
  color: lighten($text_color, 50%);
  font-size: $font_small;
  margin-bottom:0.5em;
  line-height:1.15em;
}
.has-subline{ margin-bottom:0; }

/* Lists */
.checklist {
  list-style: none outside none;
  padding-left:5px;
  li{
      margin-bottom: 0.75em;
      padding-left: 33px;
      position: relative;
      &:before {
        border: 2px solid #DFE3E4;
        border-radius: 100% 100% 100% 100%;
        color: #4db848;
        content: "h";
        font-family: $iconfont;
        font-size: 18px;
        left: 0;
        line-height: 18px;
        padding: 2px;
        position: absolute;
        top: -2px;
      }
   }
}

/* Maps */
.gmap-gmap{
  width:100% !important;
  margin-bottom:$grid_vertical;
  iframe{
    width:100%;
  }
}
.gmap-popup{
  min-width:220px;
  min-height:150px;
  line-height:normal;
  h2{
    margin:0 0 8px 0;
  }
  .views-field:not(:last-child){
    margin-bottom:5px;
  }
}
/* Get Directions */
.getdirections-inside-colorbox{
  #page{
    margin-right:$thin_grid;
  }
}
.getdirections-map{
  > div{
    max-width:100%;
    width:100% !important;
  }
}
.getdirections{
  > table,
  > table > tbody,
  > table > thead,
  > table > tbody > tr,
  > table > tbody > tr > td{
    display:block;
    width:auto;
  }
}
.getdirections_display,
.getdirections_form .form-type-textfield{
  padding-left:40px + $thin_grid;
  @include cutline(top);
  &::before{
    font-family:iconfont;
    float:left;
    width:40px;
    margin-left:-(40px + $thin_grid);
    font-size:36px;
    color:$highlight_sec;
    line-height:normal;
    @include get-ico-only('map');
  }
}
.getdirections_form{
  @include box-style();
  margin-bottom:8px;
  .description{
    font-style:italic;
  }
 .form-type-textfield{
    @include cutline(top);
    .form-text{
      width:100%;
    }
    &::before{
     color:$highlight;
    }
  }
}
div[id*="getdirections_btn"]{
  text-align:right;
  margin-top:$grid_vertical;
}
div[id*="getdirections_show_distance"],
div[id*="getdirections_show_duration"]{
  @include box-style('info');
  margin-bottom:5px;
  &:empty{
    display:none;
  }
}

/* =============================================================================
 *   jQuery.UI
 * ========================================================================== */

/* Core Theme */
/* Layout helpers
----------------------------------*/
.ui-helper-hidden {
  display: none;
}
.ui-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.ui-helper-reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  line-height: 1.3;
  text-decoration: none;
  font-size: 100%;
  list-style: none;
}
.ui-helper-clearfix:before,
.ui-helper-clearfix:after {
  content: "";
  display: table;
  border-collapse: collapse;
}
.ui-helper-clearfix:after {
  clear: both;
}
.ui-helper-clearfix {
  min-height: 0; /* support: IE7 */
}
.ui-helper-zfix {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  filter:Alpha(Opacity=0);
}

.ui-front {
  z-index: 100;
}


/* Interaction Cues
----------------------------------*/
.ui-state-disabled {
  cursor: default !important;
}


/* Icons
----------------------------------*/

/* states and images */
.ui-icon {
  display: block;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat;
}


/* Misc visuals
----------------------------------*/

/* Overlays */
.ui-widget-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.ui-resizable {
  position: relative;
}
.ui-resizable-handle {
  position: absolute;
  font-size: 0.1px;
  display: block;
}
.ui-resizable-disabled .ui-resizable-handle,
.ui-resizable-autohide .ui-resizable-handle {
  display: none;
}
.ui-resizable-n {
  cursor: n-resize;
  height: 7px;
  width: 100%;
  top: -5px;
  left: 0;
}
.ui-resizable-s {
  cursor: s-resize;
  height: 7px;
  width: 100%;
  bottom: -5px;
  left: 0;
}
.ui-resizable-e {
  cursor: e-resize;
  width: 7px;
  right: -5px;
  top: 0;
  height: 100%;
}
.ui-resizable-w {
  cursor: w-resize;
  width: 7px;
  left: -5px;
  top: 0;
  height: 100%;
}
.ui-resizable-se {
  cursor: se-resize;
  width: 12px;
  height: 12px;
  right: 1px;
  bottom: 1px;
}
.ui-resizable-sw {
  cursor: sw-resize;
  width: 9px;
  height: 9px;
  left: -5px;
  bottom: -5px;
}
.ui-resizable-nw {
  cursor: nw-resize;
  width: 9px;
  height: 9px;
  left: -5px;
  top: -5px;
}
.ui-resizable-ne {
  cursor: ne-resize;
  width: 9px;
  height: 9px;
  right: -5px;
  top: -5px;
}
.ui-selectable-helper {
  position: absolute;
  z-index: 100;
  border: 1px dotted $jq_border;
}
.ui-accordion .ui-accordion-header {
  display: block;
  cursor: pointer;
  position: relative;
  margin-top: 2px;
  padding: .5em .5em .5em .7em;
  min-height: 0; /* support: IE7 */
}
.ui-accordion .ui-accordion-icons {
  padding-left: 2.2em;
}
.ui-accordion .ui-accordion-noicons {
  padding-left: .7em;
}
.ui-accordion .ui-accordion-icons .ui-accordion-icons {
  padding-left: 2.2em;
}
.ui-accordion .ui-accordion-header .ui-accordion-header-icon {
  position: absolute;
  left: .5em;
  top: 50%;
  margin-top: -8px;
}
.ui-accordion .ui-accordion-content {
  padding: 1em 2.2em;
  border-top: 0;
  overflow: auto;
}
.ui-autocomplete {
  position: absolute;
  top: 0;
  left: 0;
  cursor: default;
}
.ui-button {
  display: inline-block;
  position: relative;
  padding: 0;
  line-height: normal;
  margin-right: .1em;
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
  overflow: visible; /* removes extra width in IE */
}
.ui-button,
.ui-button:hover,
.ui-button:active {
  text-decoration: none;
}
/* to make room for the icon, a width needs to be set here */
.ui-button-icon-only {
  width: 2.2em;
}
/* button elements seem to need a little more width */
button.ui-button-icon-only {
  width: 2.4em;
}
.ui-button-icons-only {
  width: 3.4em;
}
button.ui-button-icons-only {
  width: 3.7em;
}

/* button text element */
.ui-button .ui-button-text {
  display: block;
  line-height: normal;
}
.ui-button-text-only .ui-button-text {
  padding: .4em 1em;
}
.ui-button-icon-only .ui-button-text,
.ui-button-icons-only .ui-button-text {
  padding: .4em;
  text-indent: -9999999px;
}
.ui-button-text-icon-primary .ui-button-text,
.ui-button-text-icons .ui-button-text {
  padding: .4em 1em .4em 2.1em;
}
.ui-button-text-icon-secondary .ui-button-text,
.ui-button-text-icons .ui-button-text {
  padding: .4em 2.1em .4em 1em;
}
.ui-button-text-icons .ui-button-text {
  padding-left: 2.1em;
  padding-right: 2.1em;
}
/* no icon support for input elements, provide padding by default */
input.ui-button {
  padding: .4em 1em;
}

/* button icon element(s) */
.ui-button-icon-only .ui-icon,
.ui-button-text-icon-primary .ui-icon,
.ui-button-text-icon-secondary .ui-icon,
.ui-button-text-icons .ui-icon,
.ui-button-icons-only .ui-icon {
  position: absolute;
  top: 50%;
  margin-top: -8px;
}
.ui-button-icon-only .ui-icon {
  left: 50%;
  margin-left: -8px;
}
.ui-button-text-icon-primary .ui-button-icon-primary,
.ui-button-text-icons .ui-button-icon-primary,
.ui-button-icons-only .ui-button-icon-primary {
  left: .5em;
}
.ui-button-text-icon-secondary .ui-button-icon-secondary,
.ui-button-text-icons .ui-button-icon-secondary,
.ui-button-icons-only .ui-button-icon-secondary {
  right: .5em;
}

/* button sets */
.ui-buttonset {
  margin-right: 7px;
}
.ui-buttonset .ui-button {
  margin-left: 0;
  margin-right: -.3em;
}

/* workarounds */
/* reset extra padding in Firefox, see h5bp.com/l */
input.ui-button::-moz-focus-inner,
button.ui-button::-moz-focus-inner {
  border: 0;
  padding: 0;
}
.ui-datepicker {
  width: 17em;
  padding: .2em .2em 0;
  display: none;
}
.ui-datepicker .ui-datepicker-header {
  position: relative;
  padding: .2em 0;
}
.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
  position: absolute;
  top: 2px;
  width: 1.8em;
  height: 1.8em;
}
.ui-datepicker .ui-datepicker-prev-hover,
.ui-datepicker .ui-datepicker-next-hover {
  top: 1px;
}
.ui-datepicker .ui-datepicker-prev {
  left: 2px;
}
.ui-datepicker .ui-datepicker-next {
  right: 2px;
}
.ui-datepicker .ui-datepicker-prev-hover {
  left: 1px;
}
.ui-datepicker .ui-datepicker-next-hover {
  right: 1px;
}
.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
  display: block;
  position: absolute;
  left: 50%;
  margin-left: -8px;
  top: 50%;
  margin-top: -8px;
}
.ui-datepicker .ui-datepicker-title {
  margin: 0 2.3em;
  line-height: 1.8em;
  text-align: center;
}
.ui-datepicker .ui-datepicker-title select {
  font-size: 1em;
  margin: 1px 0;
}
.ui-datepicker select.ui-datepicker-month,
.ui-datepicker select.ui-datepicker-year {
  width: 49%;
}
.ui-datepicker table {
  width: 100%;
  font-size: .9em;
  border-collapse: collapse;
  margin: 0 0 .4em;
}
.ui-datepicker th {
  padding: .7em .3em;
  text-align: center;
  font-weight: bold;
  border: 0;
}
.ui-datepicker td {
  border: 0;
  padding: 1px;
}
.ui-datepicker td span,
.ui-datepicker td a {
  display: block;
  padding: .2em;
  text-align: right;
  text-decoration: none;
}
.ui-datepicker .ui-datepicker-buttonpane {
  background-image: none;
  margin: .7em 0 0 0;
  padding: 0 .2em;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
}
.ui-datepicker .ui-datepicker-buttonpane button {
  float: right;
  margin: .5em .2em .4em;
  cursor: pointer;
  padding: .2em .6em .3em .6em;
  width: auto;
  overflow: visible;
}
.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
  float: left;
}

/* with multiple calendars */
.ui-datepicker.ui-datepicker-multi {
  width: auto;
}
.ui-datepicker-multi .ui-datepicker-group {
  float: left;
}
.ui-datepicker-multi .ui-datepicker-group table {
  width: 95%;
  margin: 0 auto .4em;
}
.ui-datepicker-multi-2 .ui-datepicker-group {
  width: 50%;
}
.ui-datepicker-multi-3 .ui-datepicker-group {
  width: 33.3%;
}
.ui-datepicker-multi-4 .ui-datepicker-group {
  width: 25%;
}
.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header,
.ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header {
  border-left-width: 0;
}
.ui-datepicker-multi .ui-datepicker-buttonpane {
  clear: left;
}
.ui-datepicker-row-break {
  clear: both;
  width: 100%;
  font-size: 0;
}

/* RTL support */
.ui-datepicker-rtl {
  direction: rtl;
}
.ui-datepicker-rtl .ui-datepicker-prev {
  right: 2px;
  left: auto;
}
.ui-datepicker-rtl .ui-datepicker-next {
  left: 2px;
  right: auto;
}
.ui-datepicker-rtl .ui-datepicker-prev:hover {
  right: 1px;
  left: auto;
}
.ui-datepicker-rtl .ui-datepicker-next:hover {
  left: 1px;
  right: auto;
}
.ui-datepicker-rtl .ui-datepicker-buttonpane {
  clear: right;
}
.ui-datepicker-rtl .ui-datepicker-buttonpane button {
  float: left;
}
.ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current,
.ui-datepicker-rtl .ui-datepicker-group {
  float: right;
}
.ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header,
.ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
  border-right-width: 0;
  border-left-width: 1px;
}
.ui-dialog {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  padding: .2em;
  outline: 0;
}
.ui-dialog .ui-dialog-titlebar {
  padding: .4em 1em;
  position: relative;
}
.ui-dialog .ui-dialog-title {
  float: left;
  margin: .1em 0;
  white-space: nowrap;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ui-dialog .ui-dialog-titlebar-close {
  position: absolute;
  right: .3em;
  top: 50%;
  width: 20px;
  margin: -10px 0 0 0;
  padding: 1px;
  height: 20px;
}
.ui-dialog .ui-dialog-content {
  position: relative;
  border: 0;
  padding: .5em 1em;
  background: none;
  overflow: auto;
}
.ui-dialog .ui-dialog-buttonpane {
  text-align: left;
  border-width: 1px 0 0 0;
  background-image: none;
  margin-top: .5em;
  padding: .3em 1em .5em .4em;
}
.ui-dialog .ui-dialog-buttonpane .ui-dialog-buttonset {
  float: right;
}
.ui-dialog .ui-dialog-buttonpane button {
  margin: .5em .4em .5em 0;
  cursor: pointer;
}
.ui-dialog .ui-resizable-se {
  width: 12px;
  height: 12px;
  right: -5px;
  bottom: -5px;
  background-position: 16px 16px;
}
.ui-draggable .ui-dialog-titlebar {
  cursor: move;
}
.ui-menu {
  list-style: none;
  padding: 2px;
  margin: 0;
  display: block;
  outline: none;
}
.ui-menu .ui-menu {
  margin-top: -3px;
  position: absolute;
}
.ui-menu .ui-menu-item {
  margin: 0;
  padding: 0;
  width: 100%;
  /* support: IE10, see #8844 */
  list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
}
.ui-menu .ui-menu-divider {
  margin: 5px -2px 5px -2px;
  height: 0;
  font-size: 0;
  line-height: 0;
  border-width: 1px 0 0 0;
}
.ui-menu .ui-menu-item a {
  text-decoration: none;
  display: block;
  padding: 2px .4em;
  line-height: 1.5;
  min-height: 0; /* support: IE7 */
  font-weight: normal;
}
.ui-menu .ui-menu-item a.ui-state-focus,
.ui-menu .ui-menu-item a.ui-state-active {
  font-weight: normal;
  margin: -1px;
}

.ui-menu .ui-state-disabled {
  font-weight: normal;
  margin: .4em 0 .2em;
  line-height: 1.5;
}
.ui-menu .ui-state-disabled a {
  cursor: default;
}

/* icon support */
.ui-menu-icons {
  position: relative;
}
.ui-menu-icons .ui-menu-item a {
  position: relative;
  padding-left: 2em;
}

/* left-aligned */
.ui-menu .ui-icon {
  position: absolute;
  top: .2em;
  left: .2em;
}

/* right-aligned */
.ui-menu .ui-menu-icon {
  position: static;
  float: right;
}
.ui-progressbar {
  height: 2em;
  text-align: left;
  overflow: hidden;
}
.ui-progressbar .ui-progressbar-value {
  margin: -1px;
  height: 100%;
}
.ui-progressbar .ui-progressbar-overlay {
  background: url("#{$jq_image_folder}animated-overlay.gif");
  height: 100%;
  filter: alpha(opacity=25);
  opacity: 0.25;
}
.ui-progressbar-indeterminate .ui-progressbar-value {
  background-image: none;
}
.ui-slider {
  position: relative;
  text-align: left;
}
.ui-slider .ui-slider-handle {
  position: absolute;
  z-index: 2;
  width: 1.2em;
  height: 1.2em;
  cursor: default;
}
.ui-slider .ui-slider-range {
  position: absolute;
  z-index: 1;
  font-size: .7em;
  display: block;
  border: 0;
  background-position: 0 0;
}

/* For IE8 - See #6727 */
.ui-slider.ui-state-disabled .ui-slider-handle,
.ui-slider.ui-state-disabled .ui-slider-range {
  filter: inherit;
}

.ui-slider-horizontal {
  height: .8em;
}
.ui-slider-horizontal .ui-slider-handle {
  top: -.3em;
  margin-left: -.6em;
}
.ui-slider-horizontal .ui-slider-range {
  top: 0;
  height: 100%;
}
.ui-slider-horizontal .ui-slider-range-min {
  left: 0;
}
.ui-slider-horizontal .ui-slider-range-max {
  right: 0;
}

.ui-slider-vertical {
  width: .8em;
  height: 100px;
}
.ui-slider-vertical .ui-slider-handle {
  left: -.3em;
  margin-left: 0;
  margin-bottom: -.6em;
}
.ui-slider-vertical .ui-slider-range {
  left: 0;
  width: 100%;
}
.ui-slider-vertical .ui-slider-range-min {
  bottom: 0;
}
.ui-slider-vertical .ui-slider-range-max {
  top: 0;
}
.ui-spinner {
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding: 0;
  vertical-align: middle;
}
.ui-spinner-input {
  border: none;
  background: none;
  color: inherit;
  padding: 0;
  margin: .2em 0;
  vertical-align: middle;
  margin-left: .4em;
  margin-right: 22px;
}
.ui-spinner-button {
  width: 16px;
  height: 50%;
  font-size: .5em;
  padding: 0;
  margin: 0;
  text-align: center;
  position: absolute;
  cursor: default;
  display: block;
  overflow: hidden;
  right: 0;
}
/* more specificity required here to override default borders */
.ui-spinner a.ui-spinner-button {
  border-top: none;
  border-bottom: none;
  border-right: none;
}
/* vertically center icon */
.ui-spinner .ui-icon {
  position: absolute;
  margin-top: -8px;
  top: 50%;
  left: 0;
}
.ui-spinner-up {
  top: 0;
}
.ui-spinner-down {
  bottom: 0;
}

/* TR overrides */
.ui-spinner .ui-icon-triangle-1-s {
  /* need to fix icons sprite */
  background-position: -65px -16px;
}
.ui-tooltip {
  padding: 8px;
  position: absolute;
  z-index: 9999;
  max-width: 300px;
  box-shadow: $shadow;
}
body .ui-tooltip {
  border-width: 1px;
}

/* Component containers
----------------------------------*/
.ui-widget {
  font-family: inherit;
  font-size: inherit;
}
.ui-widget .ui-widget {
  font-size: 1em;
}
.ui-widget input,
.ui-widget select,
.ui-widget textarea,
.ui-widget button {
  font-family: inherit;
  font-size: 1em;
}
.ui-widget-content {
  border: 1px solid $jq_border;
  background: $jq_background;
  color: $jq_color;
}
.ui-widget-content a {
  color: $jq_color;
}
.ui-widget-header {
  border: 1px solid $jq_border;
  background: $jq_header_background;
  color: $jq_color;
  font-weight: $font_weight_bold;
}
.ui-widget-header a {
  color: $jq_color;
}

/* Interaction states
----------------------------------*/
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  border: 1px solid $jq_border;
  background: $jq_header_background;
  font-weight: normal;
  color: $jq_color;
}
.ui-state-default a{
  color: $jq_color;
  text-decoration: none;
}
.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus {
  border: 1px solid $jq_hover_border;
  background: $jq_hover_background;
  font-weight: normal;
  color: $jq_hover_color;
}
.ui-state-hover a,
.ui-state-hover a:hover,
.ui-state-focus a,
.ui-state-focus a:hover {
  color: $jq_hover_color;
  text-decoration: none;
}
.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
  border: 1px solid $highlight;
  background: $jq_active_background;
  font-weight: normal;
  color: $jq_active_color;
}
.ui-state-active a{
  color: $jq_active_color;
  text-decoration: none;
}

/* Interaction Cues
----------------------------------*/
.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
  border: 1px solid $jq_highlight_border;
  background: $jq_highlight_background;
  color: $jq_highlight_color;
}
.ui-state-highlight a,
.ui-widget-content .ui-state-highlight a,
.ui-widget-header .ui-state-highlight a {
  color: $jq_highlight_color;
}
.ui-state-error,
.ui-widget-content .ui-state-error,
.ui-widget-header .ui-state-error {
  border: 1px solid $jq_error_border;
  background: $jq_error_background;
  color: $jq_error_color;
}
.ui-state-error a,
.ui-widget-content .ui-state-error a,
.ui-widget-header .ui-state-error a {
  color: $jq_error_color;
}
.ui-state-error-text,
.ui-widget-content .ui-state-error-text,
.ui-widget-header .ui-state-error-text {
  color: $jq_error_color;
}
.ui-priority-primary,
.ui-widget-content .ui-priority-primary,
.ui-widget-header .ui-priority-primary {
  font-weight: $font_weight_bold;
}
.ui-priority-secondary,
.ui-widget-content .ui-priority-secondary,
.ui-widget-header .ui-priority-secondary {
  opacity: .7;
  filter:Alpha(Opacity=70);
  font-weight: $font_weight_normal;
}
.ui-state-disabled,
.ui-widget-content .ui-state-disabled,
.ui-widget-header .ui-state-disabled {
  opacity: .35;
  filter:Alpha(Opacity=35);
  background-image: none;
}
.ui-state-disabled .ui-icon {
  filter:Alpha(Opacity=35); /* For IE8 - See #6059 */
}

/* Icons
----------------------------------*/

/* states and images */
.ui-icon {
  width: 16px;
  height: 16px;
}
.ui-icon,
.ui-widget-content .ui-icon {
  background-image: url("#{$jq_image_folder}ui-icons_222222_256x240.png");
}
.ui-widget-header .ui-icon {
  background-image: url("#{$jq_image_folder}ui-icons_222222_256x240.png");
}
.ui-state-default .ui-icon {
  background-image: url("#{$jq_image_folder}ui-icons_888888_256x240.png");
}
.ui-state-hover .ui-icon,
.ui-state-focus .ui-icon {
  background-image: url("#{$jq_image_folder}ui-icons_454545_256x240.png");
}
.ui-state-active .ui-icon {
  background-image: url("#{$jq_image_folder}ui-icons_454545_256x240.png");
}
.ui-state-highlight .ui-icon {
  background-image: url("#{$jq_image_folder}ui-icons_ffffff_256x240.png");
}
.ui-state-error .ui-icon,
.ui-state-error-text .ui-icon {
  background-image: url("#{$jq_image_folder}ui-icons_cd0a0a_256x240.png");
}

/* positioning */
.ui-icon-blank { background-position: 16px 16px; }
.ui-icon-carat-1-n { background-position: 0 0; }
.ui-icon-carat-1-ne { background-position: -16px 0; }
.ui-icon-carat-1-e { background-position: -32px 0; }
.ui-icon-carat-1-se { background-position: -48px 0; }
.ui-icon-carat-1-s { background-position: -64px 0; }
.ui-icon-carat-1-sw { background-position: -80px 0; }
.ui-icon-carat-1-w { background-position: -96px 0; }
.ui-icon-carat-1-nw { background-position: -112px 0; }
.ui-icon-carat-2-n-s { background-position: -128px 0; }
.ui-icon-carat-2-e-w { background-position: -144px 0; }
.ui-icon-triangle-1-n { background-position: 0 -16px; }
.ui-icon-triangle-1-ne { background-position: -16px -16px; }
.ui-icon-triangle-1-e { background-position: -32px -16px; }
.ui-icon-triangle-1-se { background-position: -48px -16px; }
.ui-icon-triangle-1-s { background-position: -64px -16px; }
.ui-icon-triangle-1-sw { background-position: -80px -16px; }
.ui-icon-triangle-1-w { background-position: -96px -16px; }
.ui-icon-triangle-1-nw { background-position: -112px -16px; }
.ui-icon-triangle-2-n-s { background-position: -128px -16px; }
.ui-icon-triangle-2-e-w { background-position: -144px -16px; }
.ui-icon-arrow-1-n { background-position: 0 -32px; }
.ui-icon-arrow-1-ne { background-position: -16px -32px; }
.ui-icon-arrow-1-e { background-position: -32px -32px; }
.ui-icon-arrow-1-se { background-position: -48px -32px; }
.ui-icon-arrow-1-s { background-position: -64px -32px; }
.ui-icon-arrow-1-sw { background-position: -80px -32px; }
.ui-icon-arrow-1-w { background-position: -96px -32px; }
.ui-icon-arrow-1-nw { background-position: -112px -32px; }
.ui-icon-arrow-2-n-s { background-position: -128px -32px; }
.ui-icon-arrow-2-ne-sw { background-position: -144px -32px; }
.ui-icon-arrow-2-e-w { background-position: -160px -32px; }
.ui-icon-arrow-2-se-nw { background-position: -176px -32px; }
.ui-icon-arrowstop-1-n { background-position: -192px -32px; }
.ui-icon-arrowstop-1-e { background-position: -208px -32px; }
.ui-icon-arrowstop-1-s { background-position: -224px -32px; }
.ui-icon-arrowstop-1-w { background-position: -240px -32px; }
.ui-icon-arrowthick-1-n { background-position: 0 -48px; }
.ui-icon-arrowthick-1-ne { background-position: -16px -48px; }
.ui-icon-arrowthick-1-e { background-position: -32px -48px; }
.ui-icon-arrowthick-1-se { background-position: -48px -48px; }
.ui-icon-arrowthick-1-s { background-position: -64px -48px; }
.ui-icon-arrowthick-1-sw { background-position: -80px -48px; }
.ui-icon-arrowthick-1-w { background-position: -96px -48px; }
.ui-icon-arrowthick-1-nw { background-position: -112px -48px; }
.ui-icon-arrowthick-2-n-s { background-position: -128px -48px; }
.ui-icon-arrowthick-2-ne-sw { background-position: -144px -48px; }
.ui-icon-arrowthick-2-e-w { background-position: -160px -48px; }
.ui-icon-arrowthick-2-se-nw { background-position: -176px -48px; }
.ui-icon-arrowthickstop-1-n { background-position: -192px -48px; }
.ui-icon-arrowthickstop-1-e { background-position: -208px -48px; }
.ui-icon-arrowthickstop-1-s { background-position: -224px -48px; }
.ui-icon-arrowthickstop-1-w { background-position: -240px -48px; }
.ui-icon-arrowreturnthick-1-w { background-position: 0 -64px; }
.ui-icon-arrowreturnthick-1-n { background-position: -16px -64px; }
.ui-icon-arrowreturnthick-1-e { background-position: -32px -64px; }
.ui-icon-arrowreturnthick-1-s { background-position: -48px -64px; }
.ui-icon-arrowreturn-1-w { background-position: -64px -64px; }
.ui-icon-arrowreturn-1-n { background-position: -80px -64px; }
.ui-icon-arrowreturn-1-e { background-position: -96px -64px; }
.ui-icon-arrowreturn-1-s { background-position: -112px -64px; }
.ui-icon-arrowrefresh-1-w { background-position: -128px -64px; }
.ui-icon-arrowrefresh-1-n { background-position: -144px -64px; }
.ui-icon-arrowrefresh-1-e { background-position: -160px -64px; }
.ui-icon-arrowrefresh-1-s { background-position: -176px -64px; }
.ui-icon-arrow-4 { background-position: 0 -80px; }
.ui-icon-arrow-4-diag { background-position: -16px -80px; }
.ui-icon-extlink { background-position: -32px -80px; }
.ui-icon-newwin { background-position: -48px -80px; }
.ui-icon-refresh { background-position: -64px -80px; }
.ui-icon-shuffle { background-position: -80px -80px; }
.ui-icon-transfer-e-w { background-position: -96px -80px; }
.ui-icon-transferthick-e-w { background-position: -112px -80px; }
.ui-icon-folder-collapsed { background-position: 0 -96px; }
.ui-icon-folder-open { background-position: -16px -96px; }
.ui-icon-document { background-position: -32px -96px; }
.ui-icon-document-b { background-position: -48px -96px; }
.ui-icon-note { background-position: -64px -96px; }
.ui-icon-mail-closed { background-position: -80px -96px; }
.ui-icon-mail-open { background-position: -96px -96px; }
.ui-icon-suitcase { background-position: -112px -96px; }
.ui-icon-comment { background-position: -128px -96px; }
.ui-icon-person { background-position: -144px -96px; }
.ui-icon-print { background-position: -160px -96px; }
.ui-icon-trash { background-position: -176px -96px; }
.ui-icon-locked { background-position: -192px -96px; }
.ui-icon-unlocked { background-position: -208px -96px; }
.ui-icon-bookmark { background-position: -224px -96px; }
.ui-icon-tag { background-position: -240px -96px; }
.ui-icon-home { background-position: 0 -112px; }
.ui-icon-flag { background-position: -16px -112px; }
.ui-icon-calendar { background-position: -32px -112px; }
.ui-icon-cart { background-position: -48px -112px; }
.ui-icon-pencil { background-position: -64px -112px; }
.ui-icon-clock { background-position: -80px -112px; }
.ui-icon-disk { background-position: -96px -112px; }
.ui-icon-calculator { background-position: -112px -112px; }
.ui-icon-zoomin { background-position: -128px -112px; }
.ui-icon-zoomout { background-position: -144px -112px; }
.ui-icon-search { background-position: -160px -112px; }
.ui-icon-wrench { background-position: -176px -112px; }
.ui-icon-gear { background-position: -192px -112px; }
.ui-icon-heart { background-position: -208px -112px; }
.ui-icon-star { background-position: -224px -112px; }
.ui-icon-link { background-position: -240px -112px; }
.ui-icon-cancel { background-position: 0 -128px; }
.ui-icon-plus { background-position: -16px -128px; }
.ui-icon-plusthick { background-position: -32px -128px; }
.ui-icon-minus { background-position: -48px -128px; }
.ui-icon-minusthick { background-position: -64px -128px; }
.ui-icon-close { background-position: -80px -128px; }
.ui-icon-closethick { background-position: -96px -128px; }
.ui-icon-key { background-position: -112px -128px; }
.ui-icon-lightbulb { background-position: -128px -128px; }
.ui-icon-scissors { background-position: -144px -128px; }
.ui-icon-clipboard { background-position: -160px -128px; }
.ui-icon-copy { background-position: -176px -128px; }
.ui-icon-contact { background-position: -192px -128px; }
.ui-icon-image { background-position: -208px -128px; }
.ui-icon-video { background-position: -224px -128px; }
.ui-icon-script { background-position: -240px -128px; }
.ui-icon-alert { background-position: 0 -144px; }
.ui-icon-info { background-position: -16px -144px; }
.ui-icon-notice { background-position: -32px -144px; }
.ui-icon-help { background-position: -48px -144px; }
.ui-icon-check { background-position: -64px -144px; }
.ui-icon-bullet { background-position: -80px -144px; }
.ui-icon-radio-on { background-position: -96px -144px; }
.ui-icon-radio-off { background-position: -112px -144px; }
.ui-icon-pin-w { background-position: -128px -144px; }
.ui-icon-pin-s { background-position: -144px -144px; }
.ui-icon-play { background-position: 0 -160px; }
.ui-icon-pause { background-position: -16px -160px; }
.ui-icon-seek-next { background-position: -32px -160px; }
.ui-icon-seek-prev { background-position: -48px -160px; }
.ui-icon-seek-end { background-position: -64px -160px; }
.ui-icon-seek-start { background-position: -80px -160px; }
/* ui-icon-seek-first is deprecated, use ui-icon-seek-start instead */
.ui-icon-seek-first { background-position: -80px -160px; }
.ui-icon-stop { background-position: -96px -160px; }
.ui-icon-eject { background-position: -112px -160px; }
.ui-icon-volume-off { background-position: -128px -160px; }
.ui-icon-volume-on { background-position: -144px -160px; }
.ui-icon-power { background-position: 0 -176px; }
.ui-icon-signal-diag { background-position: -16px -176px; }
.ui-icon-signal { background-position: -32px -176px; }
.ui-icon-battery-0 { background-position: -48px -176px; }
.ui-icon-battery-1 { background-position: -64px -176px; }
.ui-icon-battery-2 { background-position: -80px -176px; }
.ui-icon-battery-3 { background-position: -96px -176px; }
.ui-icon-circle-plus { background-position: 0 -192px; }
.ui-icon-circle-minus { background-position: -16px -192px; }
.ui-icon-circle-close { background-position: -32px -192px; }
.ui-icon-circle-triangle-e { background-position: -48px -192px; }
.ui-icon-circle-triangle-s { background-position: -64px -192px; }
.ui-icon-circle-triangle-w { background-position: -80px -192px; }
.ui-icon-circle-triangle-n { background-position: -96px -192px; }
.ui-icon-circle-arrow-e { background-position: -112px -192px; }
.ui-icon-circle-arrow-s { background-position: -128px -192px; }
.ui-icon-circle-arrow-w { background-position: -144px -192px; }
.ui-icon-circle-arrow-n { background-position: -160px -192px; }
.ui-icon-circle-zoomin { background-position: -176px -192px; }
.ui-icon-circle-zoomout { background-position: -192px -192px; }
.ui-icon-circle-check { background-position: -208px -192px; }
.ui-icon-circlesmall-plus { background-position: 0 -208px; }
.ui-icon-circlesmall-minus { background-position: -16px -208px; }
.ui-icon-circlesmall-close { background-position: -32px -208px; }
.ui-icon-squaresmall-plus { background-position: -48px -208px; }
.ui-icon-squaresmall-minus { background-position: -64px -208px; }
.ui-icon-squaresmall-close { background-position: -80px -208px; }
.ui-icon-grip-dotted-vertical { background-position: 0 -224px; }
.ui-icon-grip-dotted-horizontal { background-position: -16px -224px; }
.ui-icon-grip-solid-vertical { background-position: -32px -224px; }
.ui-icon-grip-solid-horizontal { background-position: -48px -224px; }
.ui-icon-gripsmall-diagonal-se { background-position: -64px -224px; }
.ui-icon-grip-diagonal-se { background-position: -80px -224px; }


/* Misc visuals
----------------------------------*/

/* Corner radius */
.ui-corner-all,
.ui-corner-top,
.ui-corner-left,
.ui-corner-tl {
  border-top-left-radius: $jq_border_radius;
}
.ui-corner-all,
.ui-corner-top,
.ui-corner-right,
.ui-corner-tr {
  border-top-right-radius: $jq_border_radius;
}
.ui-corner-all,
.ui-corner-bottom,
.ui-corner-left,
.ui-corner-bl {
  border-bottom-left-radius: $jq_border_radius;
}
.ui-corner-all,
.ui-corner-bottom,
.ui-corner-right,
.ui-corner-br {
  border-bottom-right-radius: $jq_border_radius;
}

/* Overlays */
.ui-widget-overlay {
  background: $black_glass;
}
.ui-widget-shadow {
  margin: -8px 0 0 -8px;
  padding: 8px;
  background: $black_glass;
  border-radius: $border_radius;
}

/* Accordion */
.ui-accordion{
  .ui-accordion-header{
    background-color:$grey_light;
    border-width:0 0 1px 0;
    border-color:darken($grey_light, 8%);
    font-weight:$font_weight_bold;
  }
}

/* =============================================================================
 *   Messages
 * ========================================================================== */

/* Left and right margin are set by the global gutter width */
div.messages {
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 0 $border_radius $border_radius 0;
  border-style:solid;
  border-width:0 1px 1px 5px;
  position:relative;
  padding:8px 10px 8px 40px;
}
div.messages:before{
  font-family:$iconfont;
  position:absolute;
  left:12px;
  top:8px;
  font-size:25px;
  line-height:normal;
  text-shadow: 0 0 3px rgba(255,255,255,0.4);
}

div.messages ul {
  margin-top: 0;
  margin-bottom: 0;
}

/* Message Types */
div.status {
  border-color: $message_status $message_status lighten($message_status, 40%) $message_status;
  background-image:none;
  background-color: lighten($message_status, 55%);
  color: darken($message_status, 20%);
}
div.status:before { content:"H"; color:$message_status; }

div.warning {
  border-color: $message_warning $message_warning lighten($message_warning, 20%) $message_warning;
  background-image:none;
  background-color: lighten($message_warning, 30%);
  color: darken($message_warning, 30%);
}
div.warning:before { content:"!"; color:$message_warning; }

tr.warning {}

div.error,
tr.error {
  border-color: $message_error $message_error lighten($message_error, 30%) $message_error;
  background-image:none;
  background-color: lighten($message_error, 40%);
  color: darken($message_error, 20%);
  box-shadow:none;
}
div.error:before { content:"X"; color:$message_error; }

/* Form Errors */
input[type="text"].error,
.form-text.error,
textarea.error,
.error {
  border-color:$message_error;
}

/*
 * Unpublished nodes
 */
.node-unpublished p.unpublished,
.comment-unpublished p.unpublished {
  color: pink;
  color: rgba(239, 170, 170, 0.4);
  font-family: Impact,"Arial Narrow",Helvetica,sans-serif;
  font-size: 75px;
  font-weight: bold;
  height: 0;
  line-height: 1.2;
  margin: 0;
  padding: 0;
  overflow: visible;
  text-align: center;
  text-transform: uppercase;
  word-wrap: break-word;
}

/* Slider */
.owl-theme{
  .owl-item{
    overflow:hidden;
    .views-field-field-headerimage{
      line-height:0;
    }
  }
  .owl-controls{
    opacity:0;
    margin:0;
    transition-duration: 300ms;
    .owl-buttons{
      div{
        height:30px;
        width:30px;
        border-radius:50%;
        background:$grey_light;
        text-indent:-9000px;
        top:50%;
        position:absolute;
        margin-top:-15px;
        line-height:30px;
        opacity:1;
        padding:0;
        &:before{
          display:block;
          width:30px;
          height:30px;
          text-align:center;
          vertical-align:middle;
          position:absolute;
          left:0;
          top:0;
          text-indent:0;
          font-family:$iconfont;
          font-size:16px;
          color:$grey;
        }
        &.owl-prev{
          left:0;
          &:before{
            content:"a";
          }
        }
        &.owl-next{
          right:0;
          &:before{
            content:"d";
          }
        }
      }
    }
  }
  &:hover{
    .owl-controls{
      opacity:1.0;
    }
  }
  .overlay{
    @include overlay;
    > .inner{
      padding:15px $grid;
    }
  }
}
